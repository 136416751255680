// @flow

import React from 'react';
// import * as Scroll from 'react-scroll';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as Scroll from 'react-scroll';
import translation from '../../../../translate';

import styles from './home.module.scss';
import Section from '../../components/Section';
import Button from '../../components/Button';
import Iphone from '../../assets/img/iphone.png';

import Promo1 from '../../assets/promo/promo1.png';
import Promo2 from '../../assets/promo/promo2.png';
import Promo3 from '../../assets/promo/promo3.png';

// import PeopleGroup from '../../assets/svg/people-group.svg';
// import RouteMarkers from '../../assets/svg/route-markers.svg';
// import SolarEnergy from '../../assets/svg/solar-energy.svg';
import Map3DAnimated from '../../assets/svg/3d-map-animated.svg';

import metaValues from '../../../config';

// import ChargingPort from '../../components/ChargingPort';
// import ChargingInfo from '../../components/ChargeInformation';

// const scroll = Scroll.animateScroll;
/*
const mockData = {
  SoC: 48,
  status: 'Charging',
  connectorType: 'CCS',
  Voltage: 325.86,
  CurrentImport: 111.58,
  CurrentOffered: 125,
  PowerActiveImport: 36.4,
  maxPower: 120,
  EnergyActiveImportRegister: 22501.0008,
  timeSpendFull: '1h 15m'
};
*/

const HomeScreen = (): any => {
  const { defaultTitle, defaultDescription, defaultKeywords } = metaValues;
  const { userData } = useSelector(({ user }: any) => user);
  const { language } = useSelector(({ common }: any) => common);

  const { firstName } = userData || {};

  const isUser = firstName;

  // console.log('home render', language);

  return (
    <section className={styles.section}>
      <Helmet>
        <title>
          {defaultDescription} - {defaultTitle}
        </title>
        <meta name="description" content={defaultDescription} />
        <meta property="keywords" content={defaultKeywords} />
        <meta property="og:title" content={defaultTitle} />
        <meta property="og:description" content={defaultDescription} />
      </Helmet>

      <Section fullwidth>
        <Section className={styles.evtrips}>
          <div>
            <h1>{translation('_HOME_HEAD_TITLE', language)}</h1>
            <div>
              <Button>
                <Link
                  to={`/${language}/ev-trips`}
                  onClick={() =>
                    Scroll.animateScroll.scrollToTop({ duration: 100 })
                  }
                >
                  {translation('_HOME_HEAD_BUTTON1', language)}
                </Link>
              </Button>
            </div>
            <div>
              <Button>
                <Link
                  to={`/${language}/map-route`}
                  onClick={() =>
                    Scroll.animateScroll.scrollToTop({ duration: 100 })
                  }
                >
                  {translation('_HOME_HEAD_BUTTON2', language)}
                </Link>
              </Button>
            </div>
            <div>
              <Button>
                <Link
                  to={`/${language}/charge-out-ukraine/`}
                  onClick={() =>
                    Scroll.animateScroll.scrollToTop({ duration: 100 })
                  }
                >
                  {translation('_HOME_OUTSIDE_UKRAINE', language)}
                </Link>
              </Button>
            </div>
          </div>
        </Section>

        <Section>
          <div className={styles.intro}>
            <div>
              <h1>{translation('_HOME_HEAD_SECOND_TITLE', language)}</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: translation('_HOME_HEAD_SECOND_DESC', language)
                }}
              />
              <div className={styles.buttons}>
                <Button>
                  <Link
                    to={`/${language}/stations/`}
                    onClick={() =>
                      Scroll.animateScroll.scrollToTop({ duration: 100 })
                    }
                  >
                    {translation('_HOME_STATIONS', language)}
                  </Link>
                </Button>

                {isUser && (
                  <Button>
                    <Link
                      to={`/${language}/chargers`}
                      onClick={() =>
                        Scroll.animateScroll.scrollToTop({ duration: 100 })
                      }
                    >
                      {translation('_HOME_FIND_NEAR', language)}
                    </Link>
                  </Button>
                )}
              </div>
            </div>
            <div className={styles.iconColumn}>
              <img src={Map3DAnimated} alt="map" />
            </div>
          </div>
        </Section>

        <Section className={styles.bgrow} fullwidth />

        <Section>
          <div className={styles.row}>
            <div className={styles.text}>
              <h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: translation('_HOME_HEAD_THIRD_TITLE', language)
                  }}
                />
              </h1>
              <h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: translation('_HOME_HEAD_FOURTH_TITLE', language)
                  }}
                />
              </h1>
            </div>
            <div className={styles.image}>
              <img className={styles.iphoneImage} src={Iphone} alt="iphone" />
            </div>
          </div>
        </Section>

        <Section className={styles.information}>
          <div
            className={styles.wow}
            dangerouslySetInnerHTML={{
              __html: translation('_HOME_WOW', language)
            }}
          ></div>
        </Section>

        <Section className={styles.evv}>
          <ul>
            <li>
              <div className={styles.head}>01</div>
              <Link to={`/${language}/promo`}>
                <img src={Promo1} alt="evv" />
              </Link>
            </li>
            <li>
              <div className={styles.head}>02</div>
              <Link to={`/${language}/promo`}>
                <img src={Promo2} alt="evv" />
              </Link>
            </li>
            <li>
              <div className={styles.head}>03</div>
              <Link to={`/${language}/promo`}>
                <img src={Promo3} alt="evv" />
              </Link>
            </li>
            <li>
              <div className={styles.head}>04</div>
              <Link to={`/${language}/promo`}>
                <img src={Promo1} alt="evv" />
              </Link>
            </li>
          </ul>
        </Section>

        <Section className={styles.video}>
          <div className={styles.grid}>
            <div>
              <iframe
                src="https://www.youtube.com/embed/v9iWgpf2rcc"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              />
            </div>
            <div>
              <iframe
                src="https://www.youtube.com/embed/91imRFP0zuo"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
            </div>
          </div>
        </Section>
        {/*

        <Section className={styles.chargeArea}>
          <div className={styles.chargeGrid}>
            <div>
              <h2>Intuitive UI/UX</h2>
              <p>
                Easy expirience on charging process for custormer - clear and
                easy interface for managing charging process
              </p>

              <h2>Multi currency</h2>
              <p>
                Easy currency converting from one to another - that`s allow you
                to not worrying about exchange rates and have multiple
                currencies in one account
              </p>

              <h2>Multi tariffs</h2>
              <p>
                Multi tariffs allow to you as an owner of charging station to
                apply flexible price policy to your station. You can config
                daily, nightly, weekend and holiday tariffs.
              </p>

              <h2>Clear charge history</h2>
              <p>
                After each change session you`ll receive detailed bill with
                tariffs that were applied to your charge session
              </p>
            </div>

            <div className={styles.chargePortExample}>
              <ChargingPort data={mockData} />
            </div>

            <div className={styles.chargingPorts}>
              <ChargingInfo />
            </div>
          </div>
        </Section>

        <Section className={styles.information}>
          <div className={styles.grid}>
            <div>
              <img src={RouteMarkers} alt="map" />
            </div>
            <div>
              <h2>The broadest charging network</h2>
              <p>
                We have the largest network of electric car charging stations
                across Europe. Our charge point operator (CPO) partners cover
                all EU countries with over 250 000 charging points, letting you
                drive on through Europe.
              </p>
            </div>

            <div>
              <img src={SolarEnergy} alt="map" />
            </div>
            <div>
              <h2>The alliance of emobilty </h2>
              <p>
                Since starting as a small charging app, we’ve formed hundreds of
                partnerships with other industry pioneers. Together we’ve built
                a growing ecosystem where charge point operators (CPOs), car
                manufacturers (OEMs) and drivers across Europe all benefit from
                each other.
              </p>
            </div>

            <div>
              <img src={PeopleGroup} alt="map" />
            </div>
            <div>
              <h2>The biggest chargers base in Europe </h2>
              <p>
                Over the past 10 years, we’ve attracted a large number of
                charging stations where you can charge your car. And all you can
                do in one app! You don’t need to store money on each network
                application!
              </p>
            </div>
          </div>
        </Section>
        */}
      </Section>
    </section>
  );
};

export default HomeScreen;
